<template>
  <div>
    <div class="mb-1">
      <span
        v-if="!isComponent"
        class="link"
        @click="back"
      >
        Назад
      </span>
    </div>
    <div class="container p-2">
      <div v-if="model">
        <h1
          v-if="model.category"
          class="mb-1 text-center"
        >
          {{ model.category.name_uz }}
        </h1>
        <div class="d-flex justify-content-between">
          <p v-if="model.category">
            <b>Наименование:</b> {{ model.category.name_uz }}
          </p>
          <span
            v-can="'warehouses.product_categories-update'"
            class="link"
            @click="goTo('ProductInfo', {id:modelId})"
          >
            <feather-icon
              icon="Edit3Icon"
              size="12"
            />
            Редактировать
          </span>
        </div>
        <p><b>Количиство:</b> {{ model.quantity }}</p>
        <p
          v-if="
            ['director', 'admin', 'deputy', 'marketer'].includes(
              user.role.name
            )
          "
        ><b>Цена прихода:</b>
          <span v-if="model.cost"> {{ model.cost|formatPrice }} so'm </span>
          <span v-if="model.cost_dollar"> | {{ model.cost_dollar|formatPrice }} USD</span>
        </p>
        <p><b>Цена продажа:</b>
          <span v-if="model.price">{{ model.price|formatPrice }} so'm | </span>
          <span v-if="model.price_dollar"> {{ model.price_dollar|formatPrice }} USD</span>
        </p>
      </div>
      <hr>
      <!--  -->
      <b-tabs
        v-model="statusTab"
      >
        <b-tab
          v-for="tab in tabs"
          :key="tab.status"
          :title="tab.name"
          :active="tab.status === 1"
        />
      </b-tabs>

      <div v-if="warehouses && statusTab==2" class="w-50">
        <label for="warehouse_id">Ombor</label>
        <b-form-select
          id="warehouse_id"
          v-model="warehouse_id"
          name="name"
          value-field="id"
          text-field="name_uz"
          placeholder="Omborni tanlang"
          :options="warehouses.data"
          @change="getRemainProducts"
        />
      </div>
      <div v-if="statusTab==0">
        <div
          v-if="parties && parties.data && parties.data.length"
          class="table-responsive"
        >
          <ProviderPaymentTable
            :parties="parties"
            :is-component="isComponent"
            :user-type="userType"
            @openPartyModal="openPartyModal"
          />
        </div>
        <div v-else>
          Нет приходы
        </div>
      </div>
      <hr>
      <div v-if="statusTab==1">
        <div class="d-flex">
          <div
            v-if="contracts && contracts.data && contracts.data.length"
          >
            <h4 class="mb-1 text-center">
              По контракту
            </h4>
            <div
              v-if="contracts && contracts.data && contracts.data.length"
              class="table-responsive"
            >
              <ProductContractsTable :contracts="contracts" />
            </div>
            <div v-else>
              Нет расходы по контракту
            </div>
          </div>
          <div
            v-if="cashs && cashs.data && cashs.data.length"
          >
            <h4 class="mb-1 text-center">
              Наличными
            </h4>
            <div
              v-if="cashs && cashs.data && cashs.data.length"
              class="table-responsive"
            >
              <BuyedForCashTable :contracts="cashs" />
            </div>
            <div v-else>
              Нет расходы наличными
            </div>
          </div>
          <div v-else>
            Нет расходы
          </div>
        </div>
      </div>
      <!-- Remain (ostatki) -->
      <div v-if="statusTab==2">
        <div
          v-if="remainProducts && remainProducts.data && remainProducts.data.length"
          class="table-responsive"
        >
          <ProviderPaymentTable
            :parties="remainProducts"
            :is-component="isComponent"
            :user-type="userType"
            :is-remain="true"
            @openPartyModal="openPartyModal"
          />
        </div>
        <div v-else>
          <b class="text-center">   Не остался</b>
        </div>
      </div>
    </div>
    <!--  -->
    <b-modal
      v-if="showPartyModal"
      id="modal-lg"
      v-model="showPartyModal"
      size="xl"
      cancel-variant="outline-secondary"
      hide-footer
      no-close-on-backdrop
      centered
      @close="showPartyModal = false"
    >
      <div class="hello">
        <WarehousePartyShow
          :p-party-id="pPartyId"
          :is-component="true"
        />
      </div>
    </b-modal>
    <b-modal
      v-if="imageModal"
      id="modal-lg"
      v-model="imageModal"
      size="xl"
      cancel-variant="outline-secondary"
      hide-footer
      no-close-on-backdrop
      centered
      @close="imageModal = false"
    >
      <div class="hello">
        <ProductDetails
          :p-party-id="pPartyId"
          :is-component="true"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ProviderPaymentTable from '@/components/tables/productParty.vue'
import ProductContractsTable from '@/components/tables/productContracts.vue'
import BuyedForCashTable from '@/components/tables/buyedForCash.vue'

export default {
  name: 'Show',
  components: {
    ProviderPaymentTable,
    ProductContractsTable,
    BuyedForCashTable,
    WarehousePartyShow: () => import('@/views/warehouse/parties/show.vue'),
    ProductDetails: () => import('@/views/warehouse/products/productDetails/Edit.vue'),
  },
  props: {
    pCategoryId: {
      type: Number,
      default: () => null,
    },
    isComponent: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      imageModal: false,
      showPartyModal: false,
      pPartyId: null,
      statusTab: 0,
      model: null,
      parties: null,
      remainProducts: null,
      contracts: null,
      cashs: null,
      userType: 'provider',
      warehouse_id: null,
      tabs: [
        { status: 1, name: 'Приходы' },
        { status: 2, name: 'Расходы' },
        { status: 3, name: 'Остатки' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      warehouses: 'warehouse/GET_ITEMS',
    }),
    modelId() {
      if (this.pCategoryId) {
        return this.pCategoryId
      }
      return this.$route.params.id
    },
  },
  watch: {
    statusTab(val) {
      if (val == 1) {
        this.getVisablePaymentProducts({ product_category_id: this.modelId, relations: 'payment|category|payment.createdUser' }).then(res => {
          this.cashs = res.data
        })
      }
      if (val == 2) {
        this.getRemainProducts()
      }
    },
  },
  mounted() {
    this.getParties()
    this.getWarehousAction(this.getAll)
    this.getContractAction({ product_category_id: this.modelId, relations: 'contract.client', is_rejected: 0 }).then(res => {
      this.contracts = res.data
    })
    if (this.$route.query.warehouse_id) {
      this.warehouse_id = this.$route.query.warehouse_id
    }
  },
  methods: {
    ...mapActions({
      getItemAction: 'warehouse/getVisiblePartyProducts',
      getContractAction: 'warehouse/getVisibleContractProducts',
      getVisablePaymentProducts: 'resource/getVisablePaymentProducts',
      getWarehousAction: 'warehouse/index',
    }),
    openPartyModal(form) {
      this.pPartyId = form.id
      this.showPartyModal = true
    },
    getParties() {
      this.getItemAction({ category_id: this.modelId, relations: 'category|party.provider|party.createdUser', per_page: 9999 }).then(res => {
        if (res.data && res.data.data.length) {
          this.parties = res.data
          this.model = res.data.data[0]
          const hiredParty = res.data.data.some(el => el.party.provider_id == -1)
          if (hiredParty) {
            this.getItemAction({ category_id: this.modelId, relations: 'category|party.provider|party.createdUser|party.hiredWorkerUser' }).then(res => {
              if (res.data && res.data.data.length) {
                this.parties = res.data
                this.userType = 'hired_worker_user'
                this.model = res.data.data[0]
              }
            })
          }
        }
      })
    },
    getRemainProducts() {
      this.getItemAction({
        category_id: this.modelId, relations: 'category|party.provider|party.createdUser', appends: 'residual_quantity', has_in_warehouse: 1, per_page: 9999, warehouse_id: this.warehouse_id,
      }).then(res => {
        if (res.data && res.data.data.length) {
          this.remainProducts = res.data
          this.model = res.data.data[0]
          const hiredParty = res.data.data.some(el => el.party.provider_id == -1)
          if (hiredParty) {
            this.getItemAction({ category_id: this.modelId, relations: 'category|party.provider|party.createdUser|party.hiredWorkerUser' }).then(res => {
              if (res.data && res.data.data.length) {
                this.remainProducts = res.data
                this.userType = 'hired_worker_user'
                this.model = res.data.data[0]
              }
            })
          }
        }
      })
    },
  },
}
</script>
